import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

/* Images from old character planner site. likely need to be converted to PNGs now that those are more standard. */
// import CheckoffImage from "../images/CheckoffImage.gif"
// import ThreePictures from "../images/ThreePictures.gif"
// import ClipartRow from "../images/ClipartRow.gif"
// import Deut667 from "../images/Deut667.gif"

const AboutPage = () => (
  <Layout>
    <SEO title="About" />
    <p align="center">Coming Soon!</p>
  </Layout>
)

export default AboutPage
